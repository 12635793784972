@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");

body,
html {
  margin: 0;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-family: "Maven Pro", sans-serif !important;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --chakra-fonts-heading: "Maven Pro", sans-serif;
}

.rdrDateDisplayWrapper {
  background-color: rgb(237 242 247);
}

.rdrDateDisplayItemActive {
  border-color: rgba(26, 147, 172, 0.5);
}
.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  color: rgba(26, 147, 172, 0.25) !important;
}
.rdrInRange ~ .rdrDayNumber > span,
.rdrStartEdge ~ .rdrDayNumber > span,
.rdrEndEdge ~ .rdrDayNumber > span {
  color: #222 !important;
}

.rdrDayHovered {
  border-color: rgba(26, 147, 172, 0.5) !important;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: rgba(26, 147, 172, 0.5);
}

.rdrNextButton i {
  border-color: transparent transparent transparent rgba(26, 147, 172) !important;
}
.rdrPprevButton i {
  border-color: transparent rgba(26, 147, 172) transparent transparent !important;
}

.infoBox {
  overflow: unset !important;
}

@media screen and (max-width: 415px) {
  .rdrMonth {
    width: 100vw;
  }
}
