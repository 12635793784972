.agGridWrapr{
    font-family: var(--mavenpro);
    font-size: 25px;
    text-align: center;
    overflow: hidden;
    height: 55vh;
    position: relative;
}
.agGridTableWrapper{
    font-size: 15px;
    height: 50vh;
    position: relative;
}

.nameHeaderWrapper{
    display: grid;
    grid-template-columns: auto auto;
    margin-bottom: 35px;
}

.headerOptions{
    cursor: pointer;
    align-self: left;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
}
.paginationWrapper{
    margin-left: 15px;
}
.paginationSelection{
    width: 75px;
    height: 45px;
    color: black;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    background-color: white;
    border: none;
    border-radius: 5px;
    padding: 3px;
    font-family: var(--mavenpro);
    border: 1px solid #DDDDDD;
    font-size: 15px;
}
.paginationSelection:focus{
    outline: none;
}
.columnVisibilityWrapper{
    margin-left: 15px;
    text-align: center;
}
.searchInp{
    width: 200px;
    height: 45px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    padding: 15px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    font-family: var(--mavenpro); 
    font-size: 15px;
}
.displayCalendarBtn{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #DDDDDD;
    height: 45px;
    border-radius: 5px;
    max-width: 250px;
    margin-left: 15px;
    background-color: transparent;
}
.calIcon{
    margin-top: auto;
    margin-bottom: auto;
}
.calDate{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 2.5px;
}
.calendar{
    margin-left: 15px;
    height: 45px;
}
.dateFilterWrapper{
    align-items: center;
    display: flex;
    flex-direction: row;
    background-color: white;
    color: black;
    box-shadow: #0000003d 0px 3px 8px;
    max-width: 250px;
    height: 45px;
    border-radius: 5px;
    padding-left: 5px;
    font-size: 15px;
    border: 1px solid #DDDDDD;
}
.exportOptionsWrapper{
    height: 45px;
    width: 150px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    padding-left: 4.5px;
    color: black;
    font-family: var(--mavenpro);
    font-size: 15px;
}
.exportWrapper{
    margin-left: 15px;
}
.reportName{
    text-align: right;
    font-size: 18px;
    font-weight: 400;
    margin-top: auto;
    margin-right: 35px;
}
.dateFilterWrapper:focus{
    outline: none;
}
.dateFilterType{
    margin-left: 15px;
}
.searchInp:focus{
    outline: none;
}
.columnSelector{
    width: 75px;
    height: 35px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    border: none;
    border-radius: 5px;
    padding: 3px;
    font-family: var(--mavenpro);
}
.ag-theme-alpine {
    padding: 25px;
    margin-right: 25px;
    margin-right:35px;
    height: 725px;
    height: 75%;
    text-align: left;
    font-family: var(--mavenpro);
    width: 97%;
    position: absolute;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
    background-color: transparent;    
    font-family: var(--mavenpro);
        height: 20px;
        border-radius: 8px;
        border-color: white;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        color: black;
        height: 100%;
        position: absolute;
        padding: 10px   ;      
    /* @include ag-theme-alpine((
        font-family: var(--mavenpro),
        header-height: 20px,
        border-radius: 8px,
        border-color: white,
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px,
        foreground-color: black,
        height: 100%,
        position: absolute,
        padding: 10px,
    )) */
}

.agTable{
    /* margin-top: 35px; */
    margin-left: 10px;
    margin-right: 25px;
    margin-bottom: 20px;
}
.summaryWrapper{
    position: relative;
    margin-top: 52x 0px;
    margin-bottom: 10px;
    text-align: left;
    margin-left: 165px;
    display: flex;
    flex-direction: column;
}
.filtered{
    margin-top: 15px;
}
.selected{
    margin-top: 15px;         
}

.pdfExportWrapper{
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 15px; 
    font-size: var(--mavenpro);   
}

.rdrDefinedRangesWrapper {
    display: none;
}

.chakra-stack.AGGRID.css-n21gh5 .chakra-popover__popper.css-1qq679y{
        margin-top: 50px;
        margin-left: 20rem;
}

